.searchBarMain {
	background: #fff;
	width: calc( 100% - 400px);
	margin: 0 auto;
	height: 42px;
	margin-top: 11px;
	border-radius: 2px;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
	transition: all 0.2s ease-in-out;
	position: fixed;
	right: 94px;
	top: 0
}

.searchBarMain .searchBarSearchIcon {
	height: 100%;
	line-height: 42px;
	float: left;
	width: 52px;
	text-align: center;
	color: rgba(68, 68, 68, 0.5);
}

.searchBarMain input#searchBarInput {
	height: 42px;
	padding: 0;
	margin: 0;
	border: 0;
	box-sizing: border-box;
	background: transparent;
	width: calc(100% - 60px);
	outline: none;
	padding-right: 32px;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.76);
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
}

.searchBarMain .clearSearchBarField {
	position: absolute;
	right: 12px;
	top: 11px;
	color: rgba(0, 0, 0, 0.84);
	font-size: 20px;
}

.searchBarMain i.clearSearchBarField:hover {
	cursor: pointer;
}
